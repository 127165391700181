import { DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { SearchByVehicleService } from 'src/app/service/search-by-vehicle.service';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ClickOutsiteDirective } from '../../directive/click-outsite.directive';

@Component({
    selector: 'app-search-by-vehicle',
    templateUrl: './search-by-vehicle.component.html',
    styleUrls: ['./search-by-vehicle.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ClickOutsiteDirective, MatDialogModule, ReactiveFormsModule, FormsModule, NgxIntlTelInputModule, NgIf, NgFor, KeyValuePipe]
})
export class SearchByVehicleComponent implements OnInit{
  firstObj: number = this.appConstants.firstObj;
  initialData: {[key: string]: string} = {};
  vehicleName: string  = '';
  mmyDataExists: boolean = false;
  mmyDropDownData = {
    select_make: '',
    select_model: '',
    select_year: '',
    sub_model_1: '',
    sub_model_2: '',
    sub_model_3: ''
  }
  makes: {[key: string]:string} = {};
  selectedMakeKey: string = '';
  isMakeSelected: boolean = false;
  disableMake: boolean = false;
  
  models:{[key: string]:string} = {};
  selectedModelKey: string = '';
  isModelSelected: boolean = false;
  disableModel: boolean = true;
  
  years: {[key: string]:string} = {};
  selectedYearKey: string = '';
  isYearSelected: boolean = false;
  disableYear: boolean = true;
  
  submodels1: {[key: string]:string} = {};
  selectedSubmodel1Key: string = '';
  showSubmodel1:boolean = false;
  isSubmodel1selected: boolean = false;
  disableSubmodel1: boolean = false;
  
  submodels2: {[key: string]:string} = {};
  selectedSubmodel2Key: string = '';
  showSubmodel2:boolean = false;
  isSubmodel2selected: boolean = false;
  disableSubmodel2: boolean = false;
  
  submodels3: {[key: string]:string} = {};
  selectedSubmodel3Key: string = ''
  showSubmodel3:boolean = false;
  isSubmodel3selected: boolean = false;
  disableSubmodel3: boolean = false;

  disableSearchAgain: boolean = true;
  showSearchAgain: boolean = false;
  disableSearch: boolean = true;
  firstClickedOutside:boolean = true;
  homeSettings:any;
  showFeaturedLogo:any;

  @ViewChild('searchbyvehicledialogref') searchbyvehicledialogref: ElementRef;
  
  constructor(
    private appConstants: AppConstants,
    private searchByVehicleService: SearchByVehicleService,
    private comm: CommunicationService,
    private dialogRef: DialogRef,
    private router: Router,
    private commonService: CommanpageService
  ){}

  ngOnInit(): void {
    this.homeSettings=JSON.parse(localStorage.getItem('homeSettingData'));
    this.showFeaturedLogo=this.homeSettings[0].data.body.featured_logo;
    (async()=>{
      await this.setMMYData();
    })();
  }

  /*async setMMYData(){
    const mmyData = JSON.parse(localStorage.getItem('vehiclesearchForm')) || null;
    if(mmyData){
      this.showSearchAgain = true;
      if(mmyData?.['select_make']){
        this.selectedMakeKey = mmyData?.['select_make'] || '';
        this.mmyDropDownData['select_make'] = mmyData?.['select_make'] || '';
        this.disableMake = false;
      }
      if(mmyData?.['select_model']){
        this.selectedModelKey = mmyData?.['select_model'] || '';
        this.mmyDropDownData['select_model'] = mmyData?.['select_model'] || '';
        this.disableModel = false;
      }
      if(mmyData?.['select_year']){
        this.selectedYearKey = mmyData?.['select_year'] || '';
        this.mmyDropDownData['select_year'] = mmyData?.['select_year'] || '';
        this.disableYear = false;
      }
      if(mmyData?.['sub_model_1']){
        this.selectedSubmodel1Key = mmyData?.['sub_model_1'] || '';
        this.mmyDropDownData['sub_model_1'] = mmyData?.['sub_model_1'] || '';
        this.disableSubmodel1 = false;
      }
      if(mmyData?.['sub_model_2']){
        this.selectedSubmodel2Key = mmyData?.['sub_model_2'] || '';
        this.mmyDropDownData['sub_model_2'] = mmyData?.['sub_model_2'] || '';
        this.disableSubmodel2 = false;
      }
      if(mmyData?.['sub_model_3']){
        this.selectedSubmodel3Key = mmyData?.['sub_model_3'] || '';
        this.mmyDropDownData['sub_model_3'] = mmyData?.['sub_model_3'] || '';
        this.disableSubmodel3 = false;
      }  
      await this.fetchDropDownData();
      this.mmyDataExists = false;
      this.disableSearchAgain = false;
    }
    else{
      this.showSearchAgain = false;
      await this.fetchDropDownData();
      this.mmyDataExists = false;
      this.disableSearch = false;
    }

  }*/

  async setMMYData() {
    const mmyData = JSON.parse(localStorage.getItem('vehiclesearchForm')) || null;
    if (mmyData) {
      this.showSearchAgain = true;

      this.selectedMakeKey = mmyData['select_make'] || '';
      this.mmyDropDownData['select_make'] = mmyData['select_make'] || '';
      this.disableMake = !mmyData['select_make'];

      this.selectedModelKey = mmyData['select_model'] || '';
      this.mmyDropDownData['select_model'] = mmyData['select_model'] || '';
      this.disableModel = !mmyData['select_model'];

      this.selectedYearKey = mmyData['select_year'] || '';
      this.mmyDropDownData['select_year'] = mmyData['select_year'] || '';
      this.disableYear = !mmyData['select_year'];

      this.selectedSubmodel1Key = mmyData['sub_model_1'] || '';
      this.mmyDropDownData['sub_model_1'] = mmyData['sub_model_1'] || '';
      this.disableSubmodel1 = !mmyData['sub_model_1'];

      this.selectedSubmodel2Key = mmyData['sub_model_2'] || '';
      this.mmyDropDownData['sub_model_2'] = mmyData['sub_model_2'] || '';
      this.disableSubmodel2 = !mmyData['sub_model_2'];

      this.selectedSubmodel3Key = mmyData['sub_model_3'] || '';
      this.mmyDropDownData['sub_model_3'] = mmyData['sub_model_3'] || '';
      this.disableSubmodel3 = !mmyData['sub_model_3'];

      this.mmyDataExists = false;
      this.disableSearchAgain = false;
    } else {
      this.showSearchAgain = false;
      this.mmyDataExists = false;
      this.disableSearch = false;
    }
    await this.fetchDropDownData();
  }

  /*fetchDropDownData(): Promise<null>{
    return new Promise<null>((resolve)=>{
      this.searchByVehicleService.getDropDownDataSearchByVechicles(this.mmyDropDownData).subscribe(
        {
          next:(response) => 
          { 
            this.disacardMMYData();
            if (response[this.firstObj]?.success == true){
              const data = response[this.firstObj]?.data || null;
              if(data?.makes){
                this.makes = data?.makes || {};
                this.initialData = this.makes;
                
              }
              else{
                this.makes = {};
              }
              if(!!data?.models){
                this.isMakeSelected = true;
                this.models = data?.models || {};
              }
              else{
                this.isMakeSelected = false;
                this.models = {};;
              }
              if(data?.select_year){
                this.isModelSelected = true;
                this.years = data?.select_year || {};
              }
              else{
                this.isModelSelected = false;
                this.years = {};
              }
              if(data?.sub_model_1){
                this.isYearSelected = true;
                this.submodels1 = data?.sub_model_1 || {};
              }
              else{
                this.isYearSelected = false;
                this.submodels1 = {};
              }
              if(data?.sub_model_2){
                this.isSubmodel1selected = true;
                this.submodels2 = data?.sub_model_2 || {};
              }
              else{
                this.isSubmodel1selected = false;
                this.submodels2 = {};
              }
              if(data?.sub_model_3){
                this.isSubmodel2selected = true;
                this.submodels3 = data?.sub_model_3 || {};
              }
              else{
                this.isSubmodel2selected = false;
                this.submodels3 = {};
              }
              resolve(null);
            }
            else{
              resolve(null);
            }
          },
          error:(error: Error) => 
          {
            //later log it into a file
            // console.error('Custom Error: Unexpected Error while fetching dropdown data of Make Model Year',error);    
            resolve(null);
          },
        }
      )
    })
  }*/

  async fetchDropDownData(): Promise<void> {
    try {
      const response = await 
      this.searchByVehicleService.getDropDownDataSearchByVechicles(this.mmyDropDownData).toPromise();
      this.disacardMMYData();
      if (response[this.firstObj]?.success === true) {
        const data = response[this.firstObj]?.data || null;

        this.makes = data?.makes || {};
        this.initialData = this.makes;

        this.isMakeSelected = !!data?.models;
        this.models = data?.models || {};

        this.isModelSelected = !!data?.select_year;
        this.years = data?.select_year || {};

        this.isYearSelected = !!data?.sub_model_1;
        this.submodels1 = data?.sub_model_1 || {};

        this.isSubmodel1selected = !!data?.sub_model_2;
        this.submodels2 = data?.sub_model_2 || {};

        this.isSubmodel2selected = !!data?.sub_model_3;
        this.submodels3 = data?.sub_model_3 || {};
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  }

  disacardMMYData(){
    this.makes = this.models = this.years = this.submodels1 = this.submodels2 = this.submodels3 = {};
  }

  async onSelectMake(){
    this.mmyDropDownData['select_make'] = this.selectedMakeKey || '';
    this.mmyDropDownData['select_model'] = '';
    this.mmyDropDownData['select_year'] = '';
    this.mmyDropDownData['sub_model_1'] = '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedModelKey = ''
    this.selectedYearKey = '';
    this.selectedSubmodel1Key = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 =  false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;
    
    if (this.selectedMakeKey != '' ) {
      await this.fetchDropDownData();
    }
    else{
      this.models = this.years = {}
      this.disableModel = true;
    }
    this.disableMake = false;
    if (!this.commonService.isEmpty(this.models)){
      this.disableModel = false;
    }
    this.disableSearch = this.disableSearchAgain = false;
  }

  async onSelectModel(){
    this.mmyDropDownData['select_model']= this.selectedModelKey || '';
    this.mmyDropDownData['select_year'] = '';
    this.mmyDropDownData['sub_model_1'] = '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedYearKey = '';
    this.selectedSubmodel1Key = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    if (!this.commonService.isEmpty(this.years)) {
      this.disableYear = false;
    }
    this.disableSearch = this.disableSearchAgain = false;

  }

  async onSelectYear(){
    this.mmyDropDownData['select_year'] = this.selectedYearKey || '';
    this.mmyDropDownData['sub_model_1'] = '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedSubmodel1Key = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 = false;
    this.showSubmodel2 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    this.disableYear = false;
    this.disableSubmodel1 = false;
    if (!this.commonService.isEmpty(this.submodels1)) {
      this.showSubmodel1 = true;
    }
    this.disableSearch = this.disableSearchAgain = false;

  }

  async onSelectSubmodel1(){
    this.mmyDropDownData['sub_model_1'] = this.selectedSubmodel1Key || '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.disableSubmodel1 = true;
    this.disableSubmodel2 = false;
    this.disableSubmodel3 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3  = false;
    this.disableSearch = this.disableSearchAgain = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    this.disableYear = false;
    this.disableSubmodel1 = false;
    if (!this.commonService.isEmpty(this.submodels2)) {
      this.showSubmodel2 = true;
    }
    this.disableSearch = this.disableSearchAgain = false;

  }

  async onSelectSubmodel2(){
    this.mmyDropDownData['sub_model_2'] = this.selectedSubmodel2Key || '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.disableSubmodel1 = true;
    this.disableSubmodel2 = true;
    this.disableSubmodel3 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    this.disableYear = false;
    this.disableSubmodel1 = false;
    this.disableSubmodel2 = false;
    if (!this.commonService.isEmpty(this.submodels3)) {
      this.showSubmodel3 = true;
    }
    this.disableSearch = this.disableSearchAgain = false;

  }

  changeVehicleName(action = 'set'){
    if (action == 'set') {
      this.vehicleName = String(this.years[this.selectedYearKey] || '');
      if (!!this.makes?.[this.selectedMakeKey] ) {
        this.vehicleName = this.vehicleName.length > 0 ? this.vehicleName+ ' '+ this.makes[this.selectedMakeKey] : this.makes[this.selectedMakeKey];
      }
      if (!!this.models?.[this.selectedModelKey]) {
        this.vehicleName = this.vehicleName.length > 0 ? this.vehicleName+ ' '+ this.models[this.selectedModelKey]: this.models[this.selectedModelKey];
      }
      
      if (this.vehicleName.length > 0) {
        this.comm.vehiclenameSubject.next(this.vehicleName);
        localStorage.setItem('vehiclename', this.vehicleName);
      }else{
        localStorage.removeItem('vehiclename');
      }
      }
      
      else if(action == 'remove'){
        this.vehicleName = '';
        this.comm.vehiclenameSubject.next(this.vehicleName);
        localStorage.removeItem('vehiclename');
      }
  }

  onSearchVehicle(){
    localStorage.setItem('vehiclesearchForm',JSON.stringify(this.mmyDropDownData));
    this.changeVehicleName();
    this.dialogRef.close(SearchByVehicleComponent);
    this.router.navigate(['/vehicle-search']);

  }

  clickOutsideBox(isClickedOutside: boolean){
    if (isClickedOutside){
      if (!this.firstClickedOutside) {
        this.dialogRef.close(SearchByVehicleComponent);
      }
      this.firstClickedOutside = false;
    }
  }

  
  onClearVehicle(){
    this.disacardMMYData();
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.makes = this.initialData;
    this.selectedMakeKey = '';
    this.searchByVehicleService.clearVehicleSearch();
  }
  

}
