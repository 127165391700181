<div class="modal fade Search_by_Vehicle final-wheel-details main-container config-app in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
    <div class="modal-dialog">
        <div class="modal-content" style="" appClickOutside (clickedOutside)="clickOutsideBox($event)">
            <div class="web_commHead">
                <h1 class="text-center">
                    Search By
                    <img *ngIf="showFeaturedLogo == 'true'" src="../../../assets/images/mcLeod_header_center_img.png" class=" ls-is-cached lazyloaded" />
                    <span> Vehicle</span>
                </h1>
            </div>
            <div class="form-block center-block">
                <div style="overflow: hidden;clear: both">
                    <button type="button" class="close" data-dismiss="modal" style="border: none; background-color: transparent;" mat-dialog-close>
            ×
          </button>
                </div>
                <form class="form-horizontal" name="forma_vehicle_popup">
                    <h4 id="vehicleErrors" class="show-error-msg-popup text-center"></h4>
                    <div class="form-group has-feedback p-30" style="flex-flow: wrap;">
                        <div class="styled-select col-md-8 col-sm-8">
                            <select id="vehicle_make1" name="vehicle_make" [(ngModel)]="selectedMakeKey" class="form-control has-feedback" (ngModelChange)="onSelectMake()" [disabled]="disableMake" size="1">
                  <option value=""
                  style="height: 100px;"

                  >Select Make</option>
                  <option
                  *ngIf="mmyDataExists"
                  [value]="mmyDropDownData?.['select_make']"
                  >
                    {{ makes[selectedMakeKey] }}
                  </option>
                  <option
                  *ngFor="let make of makes | keyvalue"
                  [value]="make?.key"
                  >
                    {{make?.value}}
                  </option>
                </select>
                        </div>
                        <div class="styled-select col-md-8 col-sm-8">
                            <select size="1" name="vehicle_model" class="form-control has-feedback" [(ngModel)]="selectedModelKey" [disabled]="disableModel" (ngModelChange)="onSelectModel()">
                  <option value="">Select Model</option>
                  <option
                  *ngIf="mmyDataExists"
                  [value]="mmyDropDownData?.['select_model']"
                  >
                    {{ models[selectedModelKey] }}
                  </option>
                  <option
                  *ngFor="let model of models| keyvalue"
                  [value]="model?.key">
                    {{model?.value}}
                  </option>
                </select>
                            <input type="hidden" value="" id="vehicle_model_name" name="vehicle_model_name">
                        </div>
                        <div class="styled-select col-md-8 col-sm-8">
                            <select size="1" name="vehicle_year" class="form-control has-feedback" [(ngModel)]="selectedYearKey" [disabled]="disableYear" (ngModelChange)="onSelectYear()">
                  <option value="">Select Year</option>
                  <option
                  *ngIf="mmyDataExists"
                  [value]="mmyDropDownData?.['select_year']"
                  >
                    {{ years[selectedYearKey] }}
                  </option>
                  <option
                  *ngFor="let year of years| keyvalue"
                  [value]="year.key">
                    {{year.value}}
                  </option>
                </select>
                        </div>
                        <div *ngIf="showSubmodel1" class="styled-select col-md-8 col-sm-8 sub_model1">
                            <select size="1" name="vehicle_sub_model1" class="form-control has-feedback" [(ngModel)]="selectedSubmodel1Key" (ngModelChange)="onSelectSubmodel1()">
                  <option value="">Select</option>
                  <option
                  *ngIf="mmyDataExists"
                  [value]="mmyDropDownData?.['sub_model1']"
                  >
                    {{ submodels1[selectedSubmodel1Key] }}
                  </option>
                  <option
                  *ngFor="let submodel1 of submodels1| keyvalue"
                  [value]="submodel1.key"
                  >
                    {{submodel1.value}}
                  </option>
                </select>
                        </div>
                        <div *ngIf="showSubmodel2" class="styled-select col-md-8 col-sm-8 sub_model2">
                            <select size="1" name="vehicle_sub_model2" class="form-control has-feedback" [(ngModel)]="selectedSubmodel2Key">
                  <option value="">Select</option>
                  <option
                  *ngIf="mmyDataExists"
                  [value]="mmyDropDownData?.['sub_model2']"
                  >
                    {{ submodels2[selectedSubmodel2Key] }}
                  </option>
                  <option
                  *ngFor="let submodel2 of submodels2| keyvalue"
                  [value]="submodel2.key">
                    {{submodel2.value}}
                  </option>
                </select>
                        </div>
                        <div *ngIf="showSubmodel3" class="styled-select col-md-8 col-sm-8 sub_model3">
                            <select size="1" name="vehicle_sub_model3" class="form-control has-feedback" [(ngModel)]="selectedSubmodel3Key">
                  <option value="">Select</option>
                  <option
                  *ngIf="mmyDataExists"
                  [value]="mmyDropDownData?.['sub_model3']"
                  >
                    {{ submodels3[selectedSubmodel3Key] }}
                  </option>
                  <option
                  *ngFor="let submodel3 of submodels3 | keyvalue"
                  [value]="submodel3.key"
                  >
                    {{submodel3.value}}
                  </option>
                </select>
                        </div>
                    </div>
                    <div class="form-group has-feedback search_btn">
                        <div class="styled-select">
                            <button *ngIf="isMakeSelected" type="button" class="btn btn-group btn-default" id="enableOnInput" style="background-color: #000; border-color: #fff; margin-right: 5px !important;" (click)="onClearVehicle()" mat-dialog-close>
                  Clear
                </button>
                            <button *ngIf="!showSearchAgain" type="button" class="btn btn-group btn-default popup_add_to_cart" data-dismiss="modal" (click)="onSearchVehicle()" [disabled]="disableSearch">
                  Search
              </button>
                            <button *ngIf="showSearchAgain" type="button" class="btn btn-group btn-default popup_add_to_cart" id="enableOnInput" data-dismiss="modal" (click)="onSearchVehicle()" [disabled]="disableSearchAgain">
                Search Again
              </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>