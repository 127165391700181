import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/app.constants';
import { ProductService } from 'src/app/service/product.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { LoveItPopupComponent } from 'src/app/templates/featured-product/component/product-details/love-it-popup/love-it-popup.component';
import { ReviewItPopupComponent } from 'src/app/templates/featured-product/component/product-details/review-it-popup/review-it-popup.component';
import { WishlistPopupComponent } from './wishlist-popup/wishlist-popup.component';
import { environment } from 'src/environments/environment.prod';
import { wishlistPostDataInterface } from 'src/app/interfaces/request-interfaces/wishlist-popup.interface';
import { CartService } from 'src/app/service/cart.service';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { skuSlugPostDataInterface } from 'src/app/interfaces/request-interfaces/product.interface';
import { ProductOptionErrorpopupComponent } from './product-option/component/product-option-errorpopup/product-option-errorpopup.component';
import { SearchByVehicleComponent } from 'src/app/templates/search-by-vehicle/search-by-vehicle.component';
import { SearchByVehicleService } from 'src/app/service/search-by-vehicle.service';
import { StarArrayPipe } from '../../../../pipes/star-array.pipe';
import { DecimalPipe } from '../../../../pipes/decimal.pipe';
import { SubscribeComponent } from '../../../subscribe/subscribe.component';
import { ProductReviewComponent } from '../product-review/product-review.component';
import { RelatedProductsComponent } from '../related-products/related-products.component';
import { RecentlyViewedComponent } from '../recently-viewed/recently-viewed.component';
import { GallaryPhotosComponent } from '../gallary-photos/gallary-photos.component';
import { InstructionsComponent } from '../instructions/instructions.component';
import { WarrantyComponent } from '../warranty/warranty.component';
import { FaqsComponent } from '../faqs/faqs.component';
import { TechTipsComponent } from '../tech-tips/tech-tips.component';
import { ProductDetailInfoComponent } from '../product-detail-info/product-detail-info.component';
import { ApplicationComponent } from '../application/application.component';
import { IncludedComponentComponent } from '../included-component/included-component.component';
import { ProductOptionComponent } from './product-option/product-option.component';
import { ProductColorComponent } from './product-color/product-color.component';
import { ProductSizeComponent } from './product-size/product-size.component';
import { ProductSpecificationsComponent } from './product-specifications/product-specifications.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ProductBreadcrumbComponent } from '../product-breadcrumb/product-breadcrumb.component';
import { ProductDetailsSkeletonComponent } from '../../../pages-skeleton/component/product-details-skeleton/product-details-skeleton.component';
import { NgIf, NgClass, NgStyle, NgFor } from '@angular/common';
import { LazyLoadDirective } from 'src/app/directive/lazy-load.directive';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.css'],
    standalone: true,
    imports: [
        NgIf,
        ProductDetailsSkeletonComponent,
        ProductBreadcrumbComponent,
        NgImageSliderModule,
        NgClass,
        NgStyle,
        NgFor,
        RouterLink,
        ProductSpecificationsComponent,
        ProductSizeComponent,
        ProductColorComponent,
        ProductOptionComponent,
        IncludedComponentComponent,
        ApplicationComponent,
        ProductDetailInfoComponent,
        TechTipsComponent,
        FaqsComponent,
        WarrantyComponent,
        InstructionsComponent,
        GallaryPhotosComponent,
        RecentlyViewedComponent,
        RelatedProductsComponent,
        ProductReviewComponent,
        SubscribeComponent,
        DecimalPipe,
        StarArrayPipe,
        LazyLoadDirective
    ],
})
export class ProductDetailsComponent implements OnInit {
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath : string = environment.cdnImagePath;

  //product level
  order_key: number = null
  product_key: number = null;
  product_key_option_details: number = null;
  productName: string = '';
  productDescription: string = '';
  minPrice: number = 0;
  maxPrice: number = 0;
  samePrice: number = 0;
  isOption: boolean = false;
  isColor: boolean = false;
  isSize: boolean = false;
  isFreeShipping: boolean = false;
  variationCount: number = 0;
  warningMessage: number = 0;
  imageObject: Array<{image:string, thumbImage: string}> = [];
  imagepath: string;
  reviewsCount: number = null;

  // sku level
  isSkuActive: boolean = null;
  selectedProductVariationKey: number = null;
  selectedSku: string = '';
  selectedSkuPrice:  number = 0;
  skuDescription: string = '';
  isMaster: boolean = null;
  selectedSkuPhotos: any[] = [];
  selectedSkuSpecifications: any[] = [];
  isSelectedSkuInventoryAvailable: boolean = true;
  inventoryflag: number;

  //image variables
  mediumImage: any;

  averageTotal: any;
  totalAverageOutOf5: number = null;

  //sub-array for color , option, size
  productColor: any[] = [];
  productOptions: any[] = [];
  productSize: any[] = [];
  productVideo: any;
  productPhotos: any[] = [];
  productTechTips: any[] = [];
  productFaqs: any[] = [];
  productWarranties: any[] = [];

  //selected values for color , option, size
  selectedColorChipAlter: string;
  selectedOption: any;
  colorChipImg: any;

  //promotion
  promotionDiscount: number = null;
  productPromotion: any = {};
  promotionalPrice: number = null;
  promotion : number = null;
  promocode: number = null;
  promotionType: string = null;
  promotionDescription: string = null
  isMapPricingPromo: boolean = false;

  //size selection
  selectedSize: string = null;

  //option selection
  selectedOptionsSkus: string[] = [];
  selectedOptionsSkuPrices: number[] = [];
  selectedOptionsQtys: number[] = [];
  selectedOptionsVariationKeys: number[] = [];
  selectedOptionKeys: Array<string> = [];
  requiredGroup: {[product_option_group_key: number]: number | ''} = {};

  //after option selected
  selectedFinalPrice: number = 0;
  selectedFinalSkuString: string = '';
  selectedFinalNonPromotionalPrice: number = null;

  //select flags
  isColorSelected: boolean = null;
  isSizeSelected: boolean = null;
  isOptionSelected: boolean = null;

  //destroy Subscription
  displaySizeTypeSub: Subscription;
  displayPriceChangeTypeSub: Subscription;

  sanitizedEmbedCode: any;
  activeTab: 'image' | 'video' = 'image'; // Set default active tab to 'image'

  //flags below
  showAddToCartButton: boolean = true
  showCheckoutButton: boolean = false;
  showAddToCartForBestPriceButton: boolean = false;
  showApplicationComponent: boolean = false;
  showMasterComponent: boolean = false;
  showProductDetailsInfoComponent: boolean = false;
  showTechTipsComponent: boolean = false;
  showFAQsComponent: boolean = false;
  showWarrentyComponent: boolean = false;
  showInstructionsComponent: boolean = false;
  isloading:boolean=false;
  //application
  disableCheckoutButton: boolean = false;

  //flags top
  showViewApplications: boolean = false;
  showCompareProducts: boolean = false;

  //content
  productDetailsInfoData: string = '';
  techTipsData: any[] = [];
  faqsData: any[] = [];
  warrentiesData: any[] = [];
  instructionsData: any[] = [];
  productImg: any;

  //mmy
  isMMYOn: boolean = false;
  make_key: number;
  model_key: number;
  year: string | number;
  mmyMessage: string = 'This Product Does not Fit your'
  vehicleName: string;
  isMMYAvailable: boolean = false;

  //product-settings
  showMadeInUsaFlagSetting: boolean = true;
  showMMYNameSetting: boolean = true;
  showSkuDescriptionSetting: boolean = true;
  showQuestionsButtonSetting: boolean = true;
  showWhereToBuyButtonSettingGlobal: string;
  showWhereToBuyButtonSetting: boolean = true;
  showApplicationComponentSetting: boolean = true;
  showProductDetailsInfoComponentSetting: boolean = true;
  showAddToCartButtonMMYSetting: boolean = true;
  showSearchByVehicleButtonSetting: boolean = true;

  @ViewChild('applicationsTab') applicationsTab: ElementRef;

  //ng-image-slider
  ngImageCurrentIndex: number = 0;
  showUSAFlagForCurrentIndex: boolean;
  baseUrl: string;
  constructor(
    private appConstants: AppConstants,
    private productHttp: ProductService,
    private router: Router,
    public dialog: MatDialog,
    public comm: CommunicationService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private cartService: CartService,
    private commonPageService: CommanpageService,
    private searchByVehicleService: SearchByVehicleService
  ){}

  showAddtoCart(){
    this.showAddToCartButton = true;
    this.showAddToCartForBestPriceButton = false;
    this.showCheckoutButton = false;
    console.log('isSelectedInventory Available: ', this.isSelectedSkuInventoryAvailable)
  }

  showCheckout(){
    this.showCheckoutButton = true;
    this.showAddToCartButton = false;
    this.showAddToCartForBestPriceButton = false
  }

  showAddtoCartBestPrice(){
    this.showAddToCartForBestPriceButton = true;
    this.showAddToCartButton = false;
    this.showCheckoutButton = false;
  }

  renderMasterComponent(){
    this.showMasterComponent = true;
    this.showProductDetailsInfoComponent = false
    this.showFAQsComponent = false;
    this.showTechTipsComponent = false;
    this.showWarrentyComponent = false;
    this.showInstructionsComponent = false;
  }

  renderApplicationComponent(){
    this.showApplicationComponent = true;
    this.showProductDetailsInfoComponent = false
    this.showFAQsComponent = false;
    this.showTechTipsComponent = false;
    this.showWarrentyComponent = false;
    this.showInstructionsComponent = false;
  }

  renderProddetailsInfoComponent(){
    this.showProductDetailsInfoComponent = true
    this.showApplicationComponent = false;
    this.showFAQsComponent = false;
    this.showTechTipsComponent = false;
    this.showWarrentyComponent = false;
    this.showMasterComponent = false;
    this.showInstructionsComponent = false;
  }

  renderTechTips(){
    this.showTechTipsComponent = true;
    this.showApplicationComponent = false;
    this.showProductDetailsInfoComponent = false
    this.showFAQsComponent = false;
    this.showWarrentyComponent = false;
    this.showMasterComponent = false;
    this.showInstructionsComponent = false;
  }

  renderFAQS(){
    this.showFAQsComponent = true;
    this.showApplicationComponent = false;
    this.showProductDetailsInfoComponent = false
    this.showTechTipsComponent = false;
    this.showWarrentyComponent = false;
    this.showMasterComponent = false;
    this.showInstructionsComponent = false;
  }

  renderWarranty(){
    this.showWarrentyComponent = true;
    this.showApplicationComponent = false;
    this.showProductDetailsInfoComponent = false;
    this.showFAQsComponent = false;
    this.showTechTipsComponent = false;
    this.showMasterComponent = false;
    this.showInstructionsComponent = false;
  }
  renderNothing(){
    this.showWarrentyComponent = true;
    this.showApplicationComponent = false;
    this.showProductDetailsInfoComponent = false;
    this.showFAQsComponent = false;
    this.showTechTipsComponent = false;
    this.showMasterComponent = false;
    this.showInstructionsComponent = false;

  }

  renderInstructions(){
    this.showInstructionsComponent = true;
    this.showApplicationComponent = false;
    this.showProductDetailsInfoComponent = false;
    this.showFAQsComponent = false;
    this.showTechTipsComponent = false;
    this.showMasterComponent = false;
    this.showWarrentyComponent = true;
  }

  renderDefaultContentComponent(){
    if (!this.isSkuActive && this.variationCount  > 0 && this.showApplicationComponentSetting) {
      this.renderApplicationComponent();
    }
    else if(this.isMaster){
      this.renderMasterComponent();
    }
    else if(this.productDetailsInfoData.length > 0 && this.showProductDetailsInfoComponentSetting){
      this.renderProddetailsInfoComponent();
    }
    else if(this.techTipsData.length > 0){
      this.renderTechTips();
    }
    else if(this.faqsData.length > 0){
      this.renderFAQS();
    }
    else if(this.warrentiesData.length > 0){
      this.renderWarranty();
    }
    else if(this.instructionsData.length > 0){
      this.renderInstructions();
    }
    else{
      this.renderNothing();
    }
  }

  ngOnInit(): void {
    this.baseUrl = window.location.origin;

    (async ()=>{
      const mmyData = JSON.parse(localStorage.getItem('vehiclesearchForm')) || null;
      if(mmyData){
        this.isMMYOn = true;
        this.make_key = mmyData?.['select_make'] || null;
        this.model_key = mmyData?.['select_model'] || null;
        this.year = mmyData?.['select_year'] || '';
        this.vehicleName = localStorage.getItem('vehiclename');
      }
      //below functions will run in parallel
      await this.fetchProductSettings();
      this.setSkuPage();
      this.setProductPage();
    })();
  }

  async fetchProductSettings(){
    this.productHttp.productSettings().subscribe(
      {
        next:async (response) => 
        {
          if (response[this.firstObj]?.success == true){
            const settings = response[this.firstObj]?.data;
            this.showMadeInUsaFlagSetting = settings?.made_in_usa_setting[this.firstObj]?.made_in_usa_setting == 'true';
            this.showMMYNameSetting = settings?.show_with_product_title_on_vehicle_search_pages[this.firstObj]?.make_model_view_setting == 'true';
            this.showSkuDescriptionSetting = settings?.show_sku_description_at_product_level_when_only_one_sku_exists[this.firstObj]?.product_level_variation_name == 'true';
            this.showQuestionsButtonSetting = settings?.turn_questions_button_on_final_product_pages[this.firstObj]?.contact_us_setting == 'true';
            this.showWhereToBuyButtonSettingGlobal = settings?.turn_on_where_to_buy_button_on_final_product_pages[this.firstObj]?.where_to_buy_setting || settings?.turn_on_where_to_buy_button_on_final_product_pages[this.firstObj]?.where_to_buy_setting;
            this.showWhereToBuyButtonSetting = settings?.turn_on_where_to_buy_button_on_final_product_pages[this.firstObj]?.where_to_buy_setting == 'true';
            this.showApplicationComponentSetting = settings?.hide_applications_on_product_pages[this.firstObj]?.product_application_setting == 'false';
            this.showProductDetailsInfoComponentSetting = settings?.hide_product_details_on_product_pages[this.firstObj]?.product_details_setting == 'false';
            this.showAddToCartButtonMMYSetting = settings?.show_add_to_cart_button_on_vehicle_search_product_listing_page[this.firstObj]?.show_add_to_cart_button == 'true';
            this.showSearchByVehicleButtonSetting = settings?.hide_make_model_and_year_dropdown_on_product_detail_pages[this.firstObj]?.product_make_model_year_setting == 'false';
          }    
        }, 
        error:(error) => 
        {
          //later log it into a file
          // console.error('Custom Error: Unexpected Error while fetching data from product-option-setting API', error);    
        },
      }
    )
  }

  async setSkuPage() {
    this.route.data.subscribe({
    next: async (resolvedData) => {
      try {
        this.isloading = true;
        const exists = resolvedData?.['sku'] || false;
        if (exists) {
          const product_key = +localStorage.getItem('selectedproductkey') || null;
          const replaceDash = localStorage.getItem('replaceDashSku') === 'true';
          let checkSku = localStorage.getItem('checkSkuParam') || null;
          if (replaceDash) {
            checkSku = checkSku.replace('-', ' ');
          }
          this.order_key = +localStorage.getItem('orderId') || null;
          if (!!checkSku) {
            let checkpromotion = null;
            if (this.product_key !== product_key) {
              await this.setContentData(product_key);
              checkpromotion = true;
            } 
            else{
              checkpromotion = false;
            }
            await this.setFreshPageForSkuLevel(null, product_key, checkSku, checkpromotion);
            this.product_key = product_key;
            await this.checkPromotion(product_key);
            const product_variation_key = +localStorage.getItem('selectedproductvariationkey');
            if (this.selectedProductVariationKey !== product_variation_key) {
              this.selectedProductVariationKey = product_variation_key;
            }
            this.buttonConfiguration();
          }
        }
      } 
      catch (error) {
        console.error('Custom Error: Route Not Found...');
        this.commonPageService.pagenotfound();
      }
      finally{
        this.isloading = false;
      }
    },
  });
}

async setProductPage() {
  this.route.params.subscribe({
    next: async (params) => {
      try {
        const product_key = +localStorage.getItem('selectedproductkey') || null;
        if (!params?.['skuName'] && !!product_key) {
          this.order_key = +localStorage.getItem('orderId') || null;
          await this.setFreshPageForProductLevel(product_key, true);
          this.product_key = product_key;
        }
      } 
      catch (error) {
        console.error('Custom Error: Route Not Found...');
        this.commonPageService.pagenotfound();
      }
      finally{
        // this.isloading = false;
      }
    },
  });
}



async setProductDetails(product_key: number, product_variation_key: number) {
  try {
    // If product key changes
    await this.setContentData(product_key);

    if (this.product_key !== product_key) {
      this.product_key = product_key;
      this.selectedProductVariationKey = product_variation_key;

      // Check if it is a product level page or SKU level page
      if (this.selectedProductVariationKey != null) {
        await this.setFreshPageForSkuLevel(this.product_key, this.selectedProductVariationKey, '', true);
      } else {
        await this.setFreshPageForProductLevel(this.product_key, true);
      }

      // this.getProductReviews(product_key);
    } else {
      if (this.selectedProductVariationKey !== product_variation_key) {
        this.selectedProductVariationKey = product_variation_key;

        if (this.selectedProductVariationKey != null) {
          await this.setFreshPageForSkuLevel(this.product_key, this.selectedProductVariationKey, '', true);
        } 
        else {
          this.isSkuActive = false;
          this.renderDefaultContentComponent();
        }
      }
    }
  } catch (error) {
    console.error('Error in setProductDetails:', error);
  }
}

  async setFreshPageForSkuLevel(
    variationKey: number = this.selectedProductVariationKey,
    productKey: number = this.product_key,
    checkSku: string = '',
    toCheckPromotion: boolean = false,
  ){
    //this is  sku level page.
    await this.fetchSkuLevelData(productKey,variationKey,checkSku);
    this.buttonConfiguration();
    this.isSizeSelected = false;
    this.selectedSize = null;
    this.renderDefaultContentComponent();
    console.error('this.product_key_option_details: ',this.product_key_option_details)
    if (this.product_key_option_details != this.product_key && (this.isOption || this.isColor || this.isSize))
    {
      await this.fetchOptionsData(productKey);
    }
    if (this.isColor) {
      this.setColorImages();
      this.isColorSelected = true;
    }
    else{
      this.isColorSelected = false;
    }
    if (this.isOption) {
      //set option config here for product-details
    }
    toCheckPromotion ? this.checkPromotion(productKey): this.calculatePromotion();
  }

  async setFreshPageForProductLevel(productKey: number = this.product_key,toCheckPromotion: boolean = false){
    //this is product level page
    this.isColorSelected = false;
    this.isOptionSelected = false;
    this.selectedSize = 'All';
    await this.fetchProductLevelData(productKey);
    this.renderDefaultContentComponent();
    if (this.isColor || this.isSize || this.isOption){
      await this.fetchOptionsData(productKey);
      if (this.isSize) {
        this.isSizeSelected = true;
      }
    }
    toCheckPromotion ? this.checkPromotion(productKey) : this.calculatePromotion();
  }

  setProductImages(productPhotos: any[]){
    this.imageObject = [];
    for (let i = 0; i < productPhotos.length; i++) {
      const mediumImage = productPhotos[i]?.medium_image;
      this.imagepath = !!mediumImage && mediumImage?.length > 0 ? this.cdnImagePath + mediumImage : '../../../../../assets/images/category_landing_sf.png';
      const imageItem = {image: this.imagepath, thumbImage: this.imagepath};
      this.imageObject.push(imageItem);
    }
    this.showUSAFlagForCurrentIndex = productPhotos[0]?.made_in_usa.toLowerCase() == 'true';
  }

  getNgImageSliderArrowClick(event: any){
    if(event.action == 'next'){
      if(this.ngImageCurrentIndex != this.imageObject.length - 1){
        this.ngImageCurrentIndex +=1;
      }
      if(this.productPhotos[this.ngImageCurrentIndex]?.made_in_usa.toLowerCase() == 'true'){
        this.showUSAFlagForCurrentIndex = true;
      } 
      else{
        this.showUSAFlagForCurrentIndex = false;
      }
    }
    else if(event.action == 'previous'){
      if (this.ngImageCurrentIndex != 0) {
        this.ngImageCurrentIndex -=1;
      }
      if(this.productPhotos[this.ngImageCurrentIndex]?.made_in_usa.toLowerCase() == 'true'){
        this.showUSAFlagForCurrentIndex = true;
      } 
      else{
        this.showUSAFlagForCurrentIndex = false;
      }
    }
  }

  async fetchProductLevelData(product_key: number = this.product_key) {
    this.isloading = true;
    return new Promise<null>((resolve, reject) => {
    this.productHttp.singleProduct(product_key).subscribe({
      next: (response) => {
        if (response[this.firstObj]?.success == true) {
          this.isloading = false;
          const data = response[this.firstObj]?.data;
          this.productImg = data?.product_photos[this.firstObj]?.small_image;
          if (this.productImg) {
            localStorage.setItem('categoryImage', this.productImg);
          }
          this.isSkuActive = false;
          this.productName = data?.name || '';
          localStorage.setItem('SelectedProductName', this.productName);
          // this.isloading = false;
          this.productDescription = data?.short_description || '';
          localStorage.setItem('SelectedproductDescription', this.productDescription);
          this.productDetailsInfoData = data?.long_description || '';
          this.variationCount = data?.variationCount || 0;
          this.minPrice = data?.minPrice || null;
          this.maxPrice = data?.maxPrice || null;
          this.inventoryflag = data?.inventory == 'true' ? 1 : 0;
          this.isOption = data?.is_option == 'true';
          this.isColor = data?.is_color == 'true';
          this.isSize = data?.is_size == 'true';
          this.isFreeShipping = data?.is_shippable == 0;
          this.warningMessage = data?.warning_message || 0;
          this.productPhotos = data?.product_photos || [];
          if (!!this.showWhereToBuyButtonSettingGlobal  && this.showWhereToBuyButtonSettingGlobal.toLowerCase() == 'product_level' ) {
            this.showWhereToBuyButtonSetting = data?.product?.where_to_buy == 'true'
          }
          this.setProductImages(this.productPhotos);
          this.productVideo = data?.product_video;
          if (this.productVideo) {
            this.sanitizedEmbedCode = this.sanitizer.bypassSecurityTrustHtml(this.productVideo.video_embed_code);
          }
          resolve(null);
        } 
        else {
          reject(null);
          this.isloading = false;
          this.commonPageService.pagenotfound();
        }
      },
      error: (error: Error) => {
        console.error('Custom Error: Unexpected Error while loading Product level data', error);
        reject(null);
        this.isloading = false;
        this.commonPageService.pagenotfound();
      },
    });
  });
}

  async fetchSkuLevelData(
    product_key: number = this.product_key,
    variationKey: number = this.selectedProductVariationKey,
    checkskuString: string = ''
  ){

    return new Promise<null>((resolve, reject)=>{
      const data: skuSlugPostDataInterface = {
        product_key: product_key,
        product_variation_key: !!variationKey ? variationKey : '',
        sku: checkskuString
      }
      this.productHttp.getSkuSlug(data).subscribe(
        {
          next:(response) =>
          {
            if (response[this.firstObj]?.success == true){
              const data = response[this.firstObj]?.data[this.firstObj];
              this.isSkuActive = true;
              this.product_key_option_details = data?.product_key;
              const product_variation_key = +data?.product_variation_key || null
              localStorage.setItem('selectedproductvariationkey', String(product_variation_key));
              this.isMaster = Number(data?.is_master) == 1 ? true : false;
              if(data?.product_key != this.product_key){
                this.isOption = data?.is_option == 'true';
                this.isColor = data?.is_color == 'true';
                this.isSize = data?.is_size == 'true';
              }
              this.selectedSku = data?.sku || '';
              this.skuDescription = data?.variation_name || '';
              this.selectedSkuPrice = Number(data?.customer_price) || 0;
              this.productName = data?.product?.name || '';
              this.productDescription = data?.product?.short_description || '';
              this.isSelectedSkuInventoryAvailable = +data?.inventory_qty_on_hand > 0;
              this.selectedSkuPhotos = data?.product_variation_photos || [];
              this.inventoryflag = +response[this.firstObj]?.data?.[1]?.[this.firstObj]?.inventory;
              if (this.showWhereToBuyButtonSettingGlobal.toLowerCase() == 'product_level' ) {
                this.showWhereToBuyButtonSetting = data?.product?.where_to_buy == 'true'
              }

              this.setProductImages(this.selectedSkuPhotos);
              this.selectedSkuSpecifications = data?.product_variation_configuration || [];
              // this.isMMYAvailable = data?.isMMYAvailable;
              // this.mmyMessage = this.isMMYAvailable ? 'This Product Does not Fit Your' : 'This Product Does Not Fit Your';
              resolve(null);
            }
            else{
              reject(null);
            }
          },
          error:(error: Error) =>
          {
            //later log it into a file
            console.error('Custom Error: Unexpected Error while fetching skul level data', error);
            reject(null)
          },
        }
      )
    });
  }

  getSingleVariationPrices({
    promotionalPrice,
    price,
    selectedProductVariationKey,
    selectedSku,
    isSelectedSkuInventoryAvailable
  }){
    const name = this.commonPageService.setUrlNameField(this.productName);

    this.selectedFinalPrice = !!promotionalPrice ? promotionalPrice : price,

    this.promotionalPrice = promotionalPrice || null,
    this.selectedFinalNonPromotionalPrice = +price
    this.selectedSkuPrice = +price
    this.selectedProductVariationKey = +selectedProductVariationKey,
    this.selectedSku = selectedSku
    this.isSelectedSkuInventoryAvailable = isSelectedSkuInventoryAvailable
    this.buttonConfiguration();


  }

  getSamePrice(samePrice: number){
    this.samePrice = samePrice
  }

  fetchOptionsData(product_key: number = this.product_key){
    return new Promise<null>((resolve,reject)=>{
      this.productHttp.getOptions(product_key).subscribe(
        {
          next:(response) =>
          {
            if (response[this.firstObj]?.success == true){
              const secondObj = 1;
              const thirdObj = 2;
              const data = response[this.firstObj]?.data;
              this.product_key_option_details = data[this.firstObj]?.product_key || null;
              this.productColor = data[secondObj].color || [];
              this.productSize = data[thirdObj]?.size || [];
              this.productOptions = data[this.firstObj]?.product_option_group || [];
              resolve(null)
            }
            else{
              reject(null);
            }
          },
          error:(error: Error) =>
          {
            //later log it into a file
            // console.error('Custom Error: Unexpected Error while fetching options ', error);
            reject(null);
          },
        }
      )
    })
  }

  async setContentData(productKey: number = this.product_key){
    return new Promise<null>(async (resolve) =>{
      try {
        const data = await this.productHttp.fetchProductDetailsContent(productKey);
        this.productDetailsInfoData = data?.product_details_info || [];
        this.techTipsData = data?.product_tech_tips || [];
        this.faqsData = data?.product_faqs || [];
        this.warrentiesData = data?.product_warranties || [];
        resolve(null)
      }
      catch(error){
        console.error('Custom Error: Unexpected Error while fetching data from product-details-content api', error);
        resolve(null);
      }
    })
  }

  setSelectedColorFlags(){
    this.isColorSelected = true;
    this.isSize = false;
    this.showApplicationComponent = false;
  }

  setSelectedColorChipAlter(colorKey: number){
    if (!colorKey) {
      this.selectedColorChipAlter = null;
      return
    }
    else{
      if (this.productPhotos.length > 0) {
        for(let photo of this.productPhotos){
          if (photo.color_key == colorKey) {
            this.selectedColorChipAlter = photo.medium_image;
            break;
          }
        }
      }
    }
  }

  setColorImages(){
    let colorkey = null;
    for(let prod of this.productColor){
      if (this.selectedProductVariationKey == prod.product_variation_key) {
        this.colorChipImg = prod.color_chip_img || null;
        colorkey = prod.color_key || null;
        break;
      }
    }
    if (!this.colorChipImg && !!colorkey) {
      this.setSelectedColorChipAlter(colorkey);
    }
  }

  async onSelectColor(variationKey: number){
    await this.setFreshPageForSkuLevel(variationKey);
    this.selectedProductVariationKey = variationKey;
  }

  async onSelectSize(selectedSize: string){
    if (this.isSkuActive) {
      await this.fetchProductLevelData();
    }
    this.selectedSize = selectedSize;
    this.isColorSelected = false;
    this.isSizeSelected = true;
    this.isOptionSelected = false;

    this.selectedOptionsQtys = [];
    this.selectedOptionsSkuPrices = [];
    this.selectedOptionsSkus = [];
    this.selectedOptionsVariationKeys = [];
    this.selectedOptionKeys = [];
    //write steps when selectedSize is changed

    this.renderDefaultContentComponent();
  }

  setSelectedFinalPriceAndSku(){
    this.selectedFinalNonPromotionalPrice = this.selectedSkuPrice;
    this.selectedFinalPrice = !!this.promotion ? this.promotionalPrice : this.selectedFinalNonPromotionalPrice;
    this.selectedFinalSkuString = this.selectedSku;

    if (this.selectedOptionsSkuPrices.length > 0) {
      for(let i=0; i<this.selectedOptionsSkuPrices.length;i++){
        this.selectedFinalPrice += (this.selectedOptionsSkuPrices[i] * this.selectedOptionsQtys[i]);
        this.selectedFinalNonPromotionalPrice += (this.selectedOptionsSkuPrices[i] * this.selectedOptionsQtys[i]);
        let sku = this.selectedOptionsSkus[i];
        if (sku != '') {
          this.selectedFinalSkuString += ' | ' + sku;
        }
      }
    }
    this.selectedFinalPrice = this.selectedFinalPrice < 0 ? 0 : this.selectedFinalPrice;
  }

  onSelectOptions({
    skus,
    prices,
    qtys,
    variationKeys,
    OptionKeys,
    requiredGroup
  }){
    this.selectedOptionsSkus  = skus;
    this.selectedOptionsSkuPrices = prices;
    this.selectedOptionsQtys = qtys;
    this.selectedOptionsVariationKeys = variationKeys,
    this.requiredGroup = requiredGroup
    this.isOptionSelected = true;
    this.selectedOptionKeys = OptionKeys.map(String);
    this.setSelectedFinalPriceAndSku();
  }

  async onSelectProductReplacement({
    variationKey,
    productKey
  }){
    this.selectedOptionsSkuPrices = [];
    this.selectedOptionsQtys = [];
    this.selectedOptionsSkus = [];
    this.selectedOptionsVariationKeys = [];
    this.selectedOptionKeys = [];
    await this.setContentData(productKey);
    await this.setFreshPageForSkuLevel(variationKey, productKey,'',true);
    if (this.product_key != productKey) {
      this.product_key = productKey;
    }
    this.selectedProductVariationKey = variationKey
    this.setSelectedFinalPriceAndSku();
  }

  async checkPromotion(product_key: number = this.product_key){
    try {
      this.productPromotion = await this.productHttp.getPromotions(product_key) || {};
      this.promotionDiscount = this.productPromotion?.[this.firstObj]?.data?.[this.firstObj]?.discount || null;
      this.promotionType = this.productPromotion?.[this.firstObj]?.data?.[this.firstObj]?.promotion_type || null;
      this.promotionDescription = this.productPromotion?.[this.firstObj]?.data?.[this.firstObj]?.one_line_description || null;
      this.calculatePromotion();
    }
    catch (error) {
      console.error('Custom Error: Unexpected Error while fetching prmotion from api',error);
    }
  }

  calculatePromotion() : void {
    this.isMapPricingPromo = this.productPromotion[this.firstObj]?.data?.[this.firstObj]?.is_map_pricing_promo == "1";
    if (!this.isMapPricingPromo){
      this.promotion = this.productPromotion[this.firstObj]?.data[this.firstObj]?.discount || 0;
      if (this.promotionType == 'percentage_discount'){
        this.promotionalPrice = (this.selectedSkuPrice - (this.selectedSkuPrice * (this.promotion / 100)));
      }
      else if (this.promotionType == 'discount_flat_rate'){
        this.promotionalPrice = (this.selectedSkuPrice - this.promotion);
      }
      this.selectedFinalPrice = this.promotionalPrice;
      this.selectedFinalNonPromotionalPrice = this.selectedSkuPrice;
    }
    else{
      this.promotion = null;
      this.promotionDiscount = null;
      this.promotionType = null;
      this.promotionalPrice = null;
      this.selectedFinalNonPromotionalPrice = this.selectedSkuPrice;
      this.selectedFinalPrice = this.selectedSkuPrice;
    }
    }


  toggleTab(tab: 'image' | 'video'){
    this.activeTab = tab;
  }

  async onAddToCart(){
    const requiredGroup = Object.values(this.requiredGroup);
    if (requiredGroup.includes('')) {
      this.dialog.open(ProductOptionErrorpopupComponent);
      return;
    }
    let price = 0;
    let promotion_key=null;
    price = this.isOptionSelected ? this.selectedFinalPrice : (!!this.promotion ? this.promotionalPrice: this.selectedSkuPrice);
    const product = {
      product_key: this.product_key,
      product_variation_key: this.selectedProductVariationKey,
      sku: this.selectedSku
    }
    this.disableCheckoutButton = true;
    let product_option_key = ''
    for(let i = 0; i < this.selectedOptionKeys.length; i++){
      if (this.selectedOptionKeys[i] != ''  && this.selectedOptionKeys[i] != null)  {
        if (product_option_key.length > 0) {
          product_option_key = product_option_key+','+ this.selectedOptionKeys[i];
        }
        else{
          product_option_key = this.selectedOptionKeys[i];
        }
      }
    }
    await this.cartService.addToCart(product,this.order_key,1,price,promotion_key,false,product_option_key);
    this.comm.itemCount.next(null);
    this.showCheckout();
    this.disableCheckoutButton = false;

  }

  getDisabledButton(value: boolean){
    this.disableCheckoutButton = value;
  }

  buttonConfiguration(){
    const orderItemObject = this.cartService.getOrderItemsList();
    let items = [];
    !!orderItemObject ? items= Object.keys(orderItemObject) : null;
    items.length > 0 && items.includes(`${this.selectedProductVariationKey}`) ? this.showCheckout() : this.showAddtoCart();
  }

  scrollToApplicationsTab() {
    if (this.applicationsTab && this.applicationsTab.nativeElement) {
      this.applicationsTab.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  onClickQuestions(){
    let price = '';
    if (this.isSkuActive) {
      price = '$'+ (this.isOptionSelected ? String(this.selectedFinalPrice) : (!!this.promotion ? String(this.promotionalPrice): String(this.selectedSkuPrice)));
    }
    else{
      price = '$'+String(this.maxPrice)+' - '+'$'+String(this.minPrice);
    }
    this.router.navigate(['/contact-us'],{queryParams:{subject:this.productName+' - '+price}});
  }

  getTeotalAverageOutOf5(totalAverageOutOf5: number){
    this.totalAverageOutOf5 = totalAverageOutOf5;
  }

  getTotalReviewsCount(count: number){
    this.reviewsCount = count
  }



  openReviewItPopup(): void {
    const dialogRef = this.dialog.open(ReviewItPopupComponent, {
      // width: '300px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  openLoveItPopup(): void {
    const dialogRef = this.dialog.open(LoveItPopupComponent,{ // width: '300px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  openWishlist(): void {
    const data: wishlistPostDataInterface = {
      qty: 1,
      calculated_price: this.selectedSkuPrice,
      product_variation_key: this.selectedProductVariationKey,
      save_for_later: false
    };

    this.productHttp.wishlist(data).subscribe(
      {
        next:(response) =>
          {
            if(response.status === 'Token is Invalid' || response.status === 'Token is Expired' || response.status == 'Authorization Token not found'){
              this.router.navigate(['/customer-login']);
          }
          else{
            this.openWishlistSuccess();
          }
        },
        error:(error: Error) =>
        {
          //later log it into a file
          // console.error('Custom Error: Unexpected Error while fetching wishlist', error);
        }
      });
  }

  openWishlistSuccess(): void {
    const dialogRef = this.dialog.open(WishlistPopupComponent,{
      width: '250px',
      data: { message: 'Product added to wishlist successfully.' },
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  openSearchByVehicle(){
    this.dialog.open(SearchByVehicleComponent)
  }

  onClearSearchVehicle(){
    this.searchByVehicleService.clearVehicleSearch();
    this.make_key = null;
    this.model_key = null;
    this.year = null;
    this.isMMYOn = false;

  }

  share(platform: string) {
    const url = encodeURIComponent(`${this.baseUrl}/${this.productName}/p${this.product_key}`);
    let shareUrl = '';

    switch (platform) {
      case 'facebook':
        shareUrl = `http://www.facebook.com/sharer.php?u=${url}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${this.productDescription}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${this.productName}`;
        break;
      case 'pinterest':
        shareUrl = `http://pinterest.com/pin/create/button/?url=${url}&media=${this.cdnImagePath}${this.productImg} &description=${this.productDescription}`;
        break;
    }

    window.open(shareUrl, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=300');
  }

}
