import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { reviewsPostDataInterface } from 'src/app/interfaces/request-interfaces/review-It-popup.interface';
import { ProductService } from 'src/app/service/product.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ReviewItSubmitPopupComponent } from './review-it-submit-popup/review-it-submit-popup.component';

@Component({
    selector: 'app-review-it-popup',
    templateUrl: './review-it-popup.component.html',
    styleUrls: ['./review-it-popup.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, ReactiveFormsModule, NgIf, NgxIntlTelInputModule]
})
export class ReviewItPopupComponent implements OnInit { 
  isSubmitClicked: boolean = false;

  reviewItForm: FormGroup;
  productsreviewdata: any;
  product_key: number = null;
  isSuccess: boolean=false;

  constructor(
    private formBuilder: FormBuilder,
    private productHttp: ProductService,
    public dialogRef: MatDialogRef<ReviewItPopupComponent>,
    private dialog: MatDialog
  ) {
    
  }

  ngOnInit(): void {
    this.product_key = +localStorage.getItem('selectedproductkey') || null;
    
    this.reviewItForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      email_address: ['', [Validators.required, Validators.email]],
      comments: ['', Validators.required],
      appearance: ['0', Validators.required],
      installation: ['0', Validators.required],
      price_value: ['0', Validators.required],
      quality: ['0', Validators.required],
      product_key: this.product_key,
    });


  }

  closePopup(): void {
    this.dialogRef.close();
  }

  hiddenProductKey(){
    this.productsreviewdata = JSON.parse(
      localStorage.getItem('product-details')
    );
    this.product_key = this.productsreviewdata.product_key;
    return this.product_key;
  }

  submitReview() {
    this.isSubmitClicked = true;
        
    if (this.reviewItForm.valid) {
      this.isSubmitClicked = false;
      const data: reviewsPostDataInterface = this.reviewItForm.value;
      this.productHttp.reviewIt(data).subscribe(
        {
          next:(response) => 
          {
            // alert("Review submitted successfully!");
            this.openReviewItPopUpSuccess();
            // this.isSuccess=true;
            setTimeout(() => {
              this.resetForm();
              this.isSuccess=false;
            }, 4000);
          },
          error:(error) => 
          {
            this.isSubmitClicked = true;
            console.error('Form Submission error:', error);
          }
        }
      );
    } 
    else{
      this.isSubmitClicked = true;
      // alert("Review not submitted!");
      console.error('Form is invalid');
    }
  }
  openReviewItPopUpSuccess(): void {
    const dialogRef = this.dialog.open(ReviewItSubmitPopupComponent, {
      width: '250px',
      data: { message: 'You have successfully signed up to get the best deals.' },
    });
    dialogRef.afterClosed().subscribe(() => { });
  }

  resetForm(){
    this.reviewItForm.reset({
      appearance:'0',
      installation:'0',
      price_value:'0',
      quality:'0'
    });
  }
}
