<div class="modal fade bs-example-modal-sm-76 fruzzi_filter_popup in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="false" style="display: block;">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Tell Us How You Feel!</h4>
                <button mat-button (click)="closePopup()" type="button" style="opacity: 0.9;" class="close" data-dismiss="modal"><span aria-hidden="true" style=" color: white;">×</span><span
                        class="sr-only">Close</span></button>
            </div>
            <form role="form" class="mt-0" [formGroup]="loveItForm" (submit)="submit()">
                <div class="modal-body">
                    <div class="form-group">
                        <h4 class="Alianza_text">{{ProductName}}</h4>
                    </div>
                    <div class="form-group">
                        <input type="text" placeholder="First Name" name="first_name" id="first_name" class="form-control" formControlName="first_name">
                        <span class="form-error text-danger" id="error_first_name"></span>
                        <!-- <div class="validation-error"
                            *ngIf="loveItForm.get('first_name').invalid && (loveItForm.get('first_name').dirty || loveItForm.get('first_name').touched|| isSubmitClicked)">
                            First Name is required.
                        </div> -->
                    </div>
                    <div class="form-group">
                        <input type="email" placeholder="Email Address" name="email_address" id="email_address" class="form-control" formControlName="email_address">
                        <span class="form-error text-danger" id="error_email_address"></span>
                        <!-- <div class="validation-error"
                            *ngIf="loveItForm.get('email_address').hasError('required') && (loveItForm.get('email_address').dirty || loveItForm.get('email_address').touched || isSubmitClicked)">
                            Email is required.
                        </div> -->

                        <!-- <div class="validation-error"
                            *ngIf="loveItForm.get('email_address').hasError('email') && !loveItForm.get('email_address').hasError('required') && (loveItForm.get('email_address').dirty || loveItForm.get('email_address').touched || isSubmitClicked)">
                            Invalid email address.
                        </div> -->
                    </div>
                    <div class="form-group">
                        <textarea rows="4" placeholder="Comments" name="comments" id="comment" class="form-control" formControlName="comments"></textarea>
                        <span class="form-error text-danger" id="error_comments"></span>
                        <!-- <div class="validation-error"
                            *ngIf="loveItForm.get('comments').invalid && (loveItForm.get('comments').dirty || loveItForm.get('comments').touched|| isSubmitClicked)">
                            Comments are required.
                        </div> -->
                    </div>
                    <div class="text-center">
                        <h4>High Five Us With 5 Stars!</h4>
                        <div class="rating-container rating-xm rating-animate">
                            <div class="rating">
                                <span class="filled-stars" style="width: 100%;">
                                    <span class="star"><i class="glyphicon glyphicon-star"></i></span>
                                <span class="star"><i class="glyphicon glyphicon-star"></i></span>
                                <span class="star"><i class="glyphicon glyphicon-star"></i></span>
                                <span class="star"><i class="glyphicon glyphicon-star"></i></span>
                                <span class="star"><i class="glyphicon glyphicon-star"></i></span>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer r_mobi">
                    <h4 class="text-center loveus_color" id="mySmallModalLabel">SHARE US WITH YOUR FRIENDS</h4>
                    <ul class="social-links text-center social fruzzi_social_icons">
                        <li>
                            <a (click)="share('facebook')" class="fa fa-facebook"></a>
                        </li>
                        <li>
                            <a (click)="share('twitter')" class="fa fa-twitter"></a>
                        </li>
                        <li>
                            <a (click)="share('linkedin')" class="fa fa-linkedin"></a>
                        </li>
                        <li>
                            <a (click)="share('pinterest')" class="fa fa-pinterest"></a>
                        </li>
                    </ul>
                </div>

                <!-- <input type="hidden" value="76" class="product_key_value" name="product_key" id="product_key"
                    data-price_level="retail">
                <input type="hidden" value="https://www.mcleodracing.com/product/product_review" name="urlSubmit"
                    id="urlSubmit"> -->

                <div class="form-group">
                    <input type="hidden" value="146" name="product_key" id="product_key" [(ngModel)]="product_key" formControlName="product_key" required>

                    <div class="validation-error" *ngIf="loveItForm.get('product_key').invalid && (loveItForm.get('product_key').dirty || loveItForm.get('product_key').touched|| isSubmitClicked)">
                        Product is not valid.</div>
                    <!-- <button class="btn btn-lg btn-gray-transparent" type="submit" id="revsub">SUBMIT</button> -->
                </div>
            </form>
        </div>
    </div>
</div>