import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { AppConstants } from './app.constants';

import { ThemePermissionMatrixService } from './service/theme-permission-matrix.service';
import { footerSetting, headerSetting, homeComponentSetting, homepageSettingsResponseInterface } from './interfaces/homepagesettings.interface';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SeoService } from './service/seo.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.css',
    ],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit,OnDestroy {
  firstObj: number = this.appConstants.firstObj;
  title = 'mcleodracing';
  routerSub : Subscription;

  latitude: number | undefined;
  longitude: number | undefined;
  city:any;
  errorMessage: string | undefined;
  seodata:any;
  pageCode:any;
  
  homepageSettings: homepageSettingsResponseInterface<string> = null;
  headerSettings: headerSetting<string> = null;
  footerSettings: footerSetting<string> = null;
  routerOutletHomeSettings: homeComponentSetting<string> = null;

  themehomesetting = {
    routerOutletHomeSetting: null,
    routerOutletHomeSettingEmitter: {
      next: (val: any) => {}
    },
    homepageSettings: () => this.themePermissionMatrix.homepageSettings()
  };

  @ViewChild('routerOutletRef') routerOutletRef: ElementRef;
  constructor(
    private appConstants: AppConstants,
    private themePermissionMatrix: ThemePermissionMatrixService,
    private router: Router,
    private seoHttp:SeoService,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    // this.getgeolocation();
    this.seoparameter();

    const container = document.documentElement;
    if (container.hasAttribute('data-critters-container')) {
        container.removeAttribute('data-critters-container');
    }
    
    if (this.router.url == '/') {
      this.getHomePageComponentDisplay();
    }
      this.routerSub = this.router.events.subscribe(
        {
          next:(event) =>
          {
            if (event instanceof NavigationEnd) {
              this.routerOutletRef.nativeElement.scrollIntoView({behavior: 'instant'});
            }
          },
          error:(error: Error) => 
          {
              // console.error("Unexpected Error Happened while scrolling in App Component",error);
          },
        
        }
      );
  }

  getHomePageComponentDisplay(){
    this.themePermissionMatrix.homepageSettings().subscribe(
      {
        next:(response) =>
        {
          if (response[this.firstObj].success == true) {
            this.homepageSettings = response[this.firstObj]?.data || null;

            const headerType = this.homepageSettings?.body?.Headers?.selectedHeader || null;
            if (!!headerType) {
              if ( headerType == 'header1') {
                this.headerSettings = this.homepageSettings?.body?.Headers?.header1 || null;
              }
              else if (headerType == 'header2') {
                this.headerSettings = this.homepageSettings?.body?.Headers?.header2 || null
              }  
            }
            else{
              this.headerSettings = null;
            }

            const footerType = this.homepageSettings?.body?.Footers?.selectedFooter || null;

            if (!!footerType) {
              if(footerType == 'footer1') {
                this.footerSettings = this.homepageSettings?.body?.Footers?.footer1 || null
              }
              else if(footerType == 'footer2') {
                this.footerSettings = this.homepageSettings?.body?.Footers?.footer2 || null
              }
            }

            this.routerOutletHomeSettings = {
              Slider: this.homepageSettings?.body?.Slider || null,
              Carousel: this.homepageSettings?.body?.Carousel || null,
              featured_logo: this.homepageSettings?.body?.featured_logo||null,
              featured_category: this.homepageSettings?.body?.featured_category || null,
              featured_product: this.homepageSettings?.body?.featured_product || null,
              featured_gallery: this.homepageSettings?.body?.featured_gallery || null,
              blog_categories: this.homepageSettings?.body?.blog_categories || null,
              HomeArticles: this.homepageSettings?.body?.HomeArticles || null,
              project_vehicles: this.homepageSettings?.body?.project_vehicles || null,
              about_us: this.homepageSettings?.body?.about_us || null,
              reviews: this.homepageSettings?.body?.reviews || null,
              media_wrapper: this.homepageSettings?.body?.media_wrapper || null,
            }            
            this.themePermissionMatrix.routerOutletHomeSetting = this.routerOutletHomeSettings;
            this.themePermissionMatrix.routerOutletHomeSettingEmitter.next(null);
          }        
          else{
            // console.error('Custom Error: Unexpected Error while getting homepage-settings API response invalid')
          }
        },
        error:(error: Error) => 
        {
          // console.error('Custom Error: Unexpected Error while fetching home page settings ', error)
        },
      }
    );
  }

  seoparameter(){
    const seoparameter = { pageCode: 'home' };
    this.seoHttp.seoparameter(seoparameter).subscribe(
      (response) => {
        this.seodata = response[0].data;        
        this.titleService.setTitle(this.seodata.override_page_title || 'Default Page Title');
        this.metaService.updateTag({ name: 'description', content: this.seodata.override_meta_description || 'Default description content' });
        this.metaService.updateTag({ name: 'keywords', content: this.seodata.override_meta_keywords || 'default, keywords'});
      },
      (error) => {
        console.error('Error loading data:', error);
      }
    );
  }


  ngOnDestroy(): void {
    if (!!this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

}
