<div id="revmodal" class="modal fade testimonial-review-146 fruzzi_filter_popup in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="false" style="display: block;">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Tell Us How You Feel!</h4>
                <button mat-button (click)="closePopup()" type="button" style="opacity: 0.9;" class="close" data-dismiss="modal"><span aria-hidden="true" style=" color: white;">×</span><span
                        class="sr-only">Close</span></button>
            </div>

            <form role="form" method="post" action="https://www.mcleodracing.com/product/product_review" class="mt-0" (submit)="submitReview()" id="review_submit" novalidate="novalidate" [formGroup]="reviewItForm">
                <div class="modal-body">

                    <div *ngIf="isSuccess" [class.fade-out]="isSuccess" class="alert alert-success" role="alert">
                        
                    </div>

                    <div class="form-group">
                        <h4 class="Alianza_text">Trail Extreme</h4>
                        <input type="hidden" name="product_review_popup" id="product_review_popup" value="1">
                    </div>

                    <div class="form-group">
                        <input type="text" placeholder="First Name" name="first_name" id="first_name" class="form-control" formControlName="first_name" required>
                    </div>
                    <span class="validation-error" *ngIf="reviewItForm.get('first_name').invalid && (reviewItForm.get('first_name').dirty || reviewItForm.get('first_name').touched|| isSubmitClicked)">
                        First name is required
                    </span>

                    <div class="form-group">
                        <input type="email" placeholder="Email Address" name="email_address" id="email_address" class="form-control" formControlName="email_address">
                        <span class="form-error text-danger" id="error_email_address"></span>

                        <div class="validation-error" *ngIf="reviewItForm.get('email_address').hasError('email') && !reviewItForm.get('email_address').hasError('required') && (reviewItForm.get('email_address').dirty || reviewItForm.get('email_address').touched || isSubmitClicked)">
                            Invalid email address.
                        </div>
                    </div>
                    <span class="validation-error" *ngIf="reviewItForm.get('email_address').hasError('required') && (reviewItForm.get('email_address').dirty || reviewItForm.get('email_address').touched || isSubmitClicked)">
                        Email is required.
                    </span>
                    <div class="form-group">
                        <textarea rows="4" placeholder="Comments" name="comments" id="comment" class="form-control" formControlName="comments"></textarea>
                        <span class="form-error text-danger" id="error_comments"></span>
                    </div>
                    <span class="validation-error" *ngIf="reviewItForm.get('comments').invalid && (reviewItForm.get('comments').dirty || reviewItForm.get('comments').touched|| isSubmitClicked)">
                        Comments are required.
                    </span>

                    <div class="form-group">
                        <select class="form-control" name="appearance" id="appearance" formControlName="appearance" required>
                            <option value="0" selected>Select Appearance</option>
                            <option value="1">1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                            <option value="2.5">2.5</option>
                            <option value="3">3</option>
                            <option value="3.5">3.5</option>
                            <option value="4">4</option>
                            <option value="4.5">4.5</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <span class="validation-error" *ngIf="reviewItForm.get('appearance').invalid && (reviewItForm.get('appearance').dirty || reviewItForm.get('appearance').touched|| isSubmitClicked)">
                        Appearance are required.
                    </span>

                    <div class="form-group">
                        <select class="form-control" name="installation" id="review_installation" formControlName="installation" required>
                            <option value="0" selected>Select Installation</option>
                            <option value="1">1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                            <option value="2.5">2.5</option>
                            <option value="3">3</option>
                            <option value="3.5">3.5</option>
                            <option value="4">4</option>
                            <option value="4.5">4.5</option>
                            <option value="5">5</option>
                        </select>

                    </div>
                    <span class="validation-error" *ngIf="reviewItForm.get('installation').invalid && (reviewItForm.get('installation').dirty || reviewItForm.get('installation').touched|| isSubmitClicked)">
                        Installation rating is required.
                    </span>

                    <div class="form-group">
                        <select class="form-control" name="price_value" id="review_price_value" formControlName="price_value" required>
                            <option value="0" selected>Select Price / Value</option>
                            <option value="1">1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                            <option value="2.5">2.5</option>
                            <option value="3">3</option>
                            <option value="3.5">3.5</option>
                            <option value="4">4</option>
                            <option value="4.5">4.5</option>
                            <option value="5">5</option>
                        </select>

                    </div>
                    <div class="validation-error" *ngIf="reviewItForm.get('price_value').invalid && (reviewItForm.get('price_value').dirty || reviewItForm.get('price_value').touched|| isSubmitClicked)">
                        Price value rating is required.
                    </div>

                    <div class="form-group">
                        <select class="form-control" name="quality" id="quality" formControlName="quality" required>
                            <option value="0" selected>Select Quality</option>
                            <option value="1">1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                            <option value="2.5">2.5</option>
                            <option value="3">3</option>
                            <option value="3.5">3.5</option>
                            <option value="4">4</option>
                            <option value="4.5">4.5</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div class="validation-error" *ngIf="reviewItForm.get('quality').invalid && (reviewItForm.get('quality').dirty || reviewItForm.get('quality').touched|| isSubmitClicked)">
                        Quality is required.
                    </div>
                    <!-- <div class="row mt-10">
                      <div class="g-recaptcha" name="g-recaptcha-response"
                          data-sitekey="6LfJoawUAAAAANnTbhPzvszGi9GOF2d9VL4_bRwT"
                          style="transform: scale(0.77); -webkit-transform: scale(0.90);">
                          <div style="width: 304px; height: 78px;">
                              <div><iframe title="reCAPTCHA" width="304" height="78" role="presentation"
                                      name="a-z5vm2sjt219l" frameborder="0" scrolling="no"
                                      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                      src="./Trail Extreme_files/anchor.html"></iframe></div><textarea
                                  id="g-recaptcha-response" name="g-recaptcha-response"
                                  class="g-recaptcha-response"
                                  style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea>
                          </div><iframe style="display: none;"
                              src="./Trail Extreme_files/saved_resource(1).html"></iframe>
                      </div>
                  </div>
                  <div>
                      <div id="g-recaptcha-error"></div>
                  </div> -->

                    <div class="form-group">
                        <input type="hidden" value="146" name="product_key" id="product_key" [(ngModel)]="product_key" formControlName="product_key" required>

                        <div class="validation-error" *ngIf="reviewItForm.get('product_key').invalid && (reviewItForm.get('product_key').dirty || reviewItForm.get('product_key').touched|| isSubmitClicked)">
                            Product is not valid.</div>

                        <button class="btn btn-lg btn-gray-transparent" type="submit" id="revsub">SUBMIT</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>