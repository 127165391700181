<div *ngIf="categoriesdetails" class="wheel_category_landing_page_background wicked_category_wrapper">
    <div class="categories_bg_wrapper" rel="preload" role="img" aria-label="Domestic Single Disc" title="Domestic Single Disc" [style.background-image]="'url(' + mainImage + ')'" loading="lazy">
        <!-- [style.background-image]="'url(' + cdnImagePath + categoriesdetails[0]?.title_image + ')'" -->
    </div>
    <div class="container">
        <div class="wheel_category_landing_page_header">
            <h1>{{categoriesdetails[firstObj]?.name}} </h1>
            <div id="catdesc" class="hidden-xs">
                <span style="font-size:16px; " [innerHTML]="categoriesdetails[firstObj]?.full_description">
				</span>
                <br>
                <br>
            </div>
        </div>
        <div *ngIf="(!!categoryFilters && (subCategoriesCount+subProductsCount+universalProductsCount > 1)) || isFilterApplied" class="container">
            <div class="wheel_category_landing_menu_strip" style="width: 100%; margin-bottom: 0px;">
                <div class="filters">
                    <ul class="nav-pills" style="padding-left:0;">
                        <li *ngIf="(categoryFilters | keyvalue ).length > 0" [ngClass]="{'border-b': filter_key == null }">
                            <a (click)="onSelectShowAll()">
								All
							</a>
                        </li>
                        <div class="row filterrow">
                            <div *ngFor="let filter of categoryFilters | keyvalue" class="col-md-3 col-sm-3">
                                <a [ngClass]="{'filter-border-bottom': filter_key == filter.key}" (click)="onSelectFilter(filter.key)">
                                    {{filter.value}}
                                </a>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>