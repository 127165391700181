import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-review-it-submit-popup',
  templateUrl: './review-it-submit-popup.component.html',
  styleUrls: ['./review-it-submit-popup.component.css'],
  standalone: true
})
export class ReviewItSubmitPopupComponent {
  
   constructor(
      public dialogRef: MatDialogRef<ReviewItSubmitPopupComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
   
    closePopup(): void {
      this.dialogRef.close();
    }
  
}
