import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { headerSetting } from '../interfaces/homepagesettings.interface';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { HeaderTopComponent } from './header-top/header-top.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [HeaderTopComponent, HeaderNavComponent]
})
export class HeaderComponent implements OnChanges{
  showSearchBar: boolean = false;
  showFirstRowHeader: boolean = null;
  showSecondRowHeader: boolean = null;
  showLogo: boolean = null;
  showMyAccount: boolean = null;
  showWebsiteHeader: boolean = null;
  showThirdRowHeader: boolean = null;
  showFlag : boolean =null;

  @Input() headerSettings: headerSetting<string> = null;


  onSearchBarEvent(event: boolean){
    this.showSearchBar =  event;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['headerSettings']?.currentValue) {
      this.headerSettings = changes['headerSettings']?.currentValue;
      this.showFirstRowHeader = (this.headerSettings.first_row_header === 'true');
      this.showSecondRowHeader = (this.headerSettings.second_row_header === 'true');
      this.showLogo = (this.headerSettings.logo === 'true');
      this.showMyAccount = (this.headerSettings.my_account === 'true');
      this.showFlag=(this.headerSettings.made_in_usa_flag === 'true');
    }
  }
}
