import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, EventEmitter, Output, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';

import { environment } from 'src/environments/environment.prod';

import { debounceTime, Subject, Subscription, switchMap } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';

import { AuthHttpService } from 'src/app/service/auth-http.service';
import { HeaderService } from 'src/app/service/header.service';
import { ProductService } from 'src/app/service/product.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { CommanpageService } from 'src/app/service/commanpages.service';

import { ProductVariationSearchInterface, ProductsSearchInterface } from 'src/app/interfaces/product.interface';
import { HeadersDataInterface } from 'src/app/interfaces/header.interface';
import { SearchByVehicleComponent } from 'src/app/templates/search-by-vehicle/search-by-vehicle.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssociateCustomerListComponent } from './component/associate-customer-list/associate-customer-list.component';
import { NgIf, NgFor, NgStyle } from '@angular/common';


@Component({
    selector: 'app-header-top',
    templateUrl: './header-top.component.html',
    styleUrls: ['./header-top.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, NgFor, AssociateCustomerListComponent, NgStyle, MatTooltipModule, ReactiveFormsModule, NgxIntlTelInputModule, FormsModule]
})
export class HeaderTopComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  firstObj: number = this.appConstants.firstObj;
  storeImagePath: string = environment.storeImagePath;
  product_key: number = null;
  product_variation_key = null;
  username:string = localStorage.getItem('Username');
  usertoken:string;

  showCorporateDropdown = false;
  dropdownData: any[] = [];

  headerdata: HeadersDataInterface =
  {
    websiteHeader: [],
    firstRow: [],
    secondRow: [],
    thirdRow: []
  };

  order_item_key: number;
  cartItems: number = 0;
  cartdata: 0;
  order_key: number;
  order_items: number;
  isProductPage: boolean = false;

  dropdownSearchMenu: 'block' | 'none' = 'none';

  searchByPartNameInput: 'block' | 'none' = 'none'
  searchByPartHashInput: 'block' | 'none' = 'none'

  searchValueByPartName: string = "";
  searchValueByPartHash: string = "";

  searchResultArrayByPartName: Array<ProductsSearchInterface>;
  searchResultArrayByPartHash: Array<ProductVariationSearchInterface>;

  usernameSub: Subscription;
  corporateUserSub: Subscription;
  dropdownDataSub: Subscription;
  private searchSubject = new Subject<string>();
  private partHashSubject = new Subject<string>();

  @ViewChild('searchByPartNameDiv') searchByPartNameDiv: ElementRef;
  @ViewChild('searchByPartHashDiv') searchByPartHashDiv: ElementRef;
  @ViewChild('dropdownMenuSearchIcon') dropdownMenuSearchIcon: ElementRef;

  @ViewChild('SearchOptions') SearchOptions: ElementRef;

  @Output() searchBarEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() showFirstRowHeader: boolean = null;
  @Input() showSecondRowHeader: boolean = null;
  @Input() showLogo: boolean = null;
  @Input() showMyAccount: boolean = null;
  @Input() showWebsiteHeader: boolean = true;
  @Input() showFlag: boolean = null;
  showMessage: boolean;

  constructor(
    private appConstants: AppConstants,
    private router: Router,
    private route: ActivatedRoute,
    private authHttpService: AuthHttpService,
    private headerHttp: HeaderService,
    private productService: ProductService,
    private comm: CommunicationService,
    private commonService: CommanpageService,
    private dialog: MatDialog
  ) {
    this.usernameObservable();
    this.searchChangeByPartName();
    this.searchChangeByPartHash();
  }




  ngOnInit(): void {
    this.product_key = +localStorage.getItem('selectedproductkey') || null;
    this.product_variation_key = +localStorage.getItem('selectedproductvariationkey') || null;
    this.headerHttp.headerList().subscribe(data => {
      this.headerdata = data[this.firstObj].data;
      console.log('headerData:',this.headerdata);

    });
    this.usertoken = this.authHttpService.getToken();
    this.order_items = +localStorage.getItem('orderId');
    this.username = localStorage.getItem('Username');
    this.getOrderItems();
    this.headerDropdownList();

    this.route.params.subscribe(
      {
        next:(params: Params) =>
        {

          let id: string = params['id'];
          if(id){
            const pattern: boolean = /^[0-9]*$/.test(id.slice(1,id.length));
            if (id.startsWith('p') && id.length > 0 && pattern){
              this.isProductPage = true;
            }
            else{
              this.isProductPage = false;
            }
          }

        },
      }
    )
  }

  headerDropdownList() {
    this.showCorporateDropdown = localStorage.getItem('isCorporateUser') === 'true';

    this.comm.isCorporateUser$.subscribe(isCorporate => {
      this.showCorporateDropdown = isCorporate;
      this.showWebsiteHeader=!isCorporate;
    });
    this.comm.dropdownData$.subscribe(data => {
      this.dropdownData = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showFirstRowHeader'] && changes['showFirstRowHeader'].currentValue) {
      this.showFirstRowHeader = changes['showFirstRowHeader'].currentValue;
    }
    if (changes['showSecondRowHeader'] && changes['showSecondRowHeader'].currentValue) {
      this.showSecondRowHeader = changes['showSecondRowHeader'].currentValue;
    }
    if (changes['showFlag'] && changes['showFlag'].currentValue) {
      this.showFlag = changes['showFlag'].currentValue;
    }
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', (event) => {
      const isInsideSearchByPartName = this.searchByPartNameDiv.nativeElement.contains(event.target);
      const isInsideSearchByPartHash = this.searchByPartHashDiv.nativeElement.contains(event.target);
      const isInsideDropdownMenuSearchIcon = this.dropdownMenuSearchIcon?.nativeElement.contains(event.target);
      if (!isInsideSearchByPartName && !isInsideDropdownMenuSearchIcon) {
        this.searchByPartNameInput = 'none';
        this.searchValueByPartName = "";

      }
      if (!isInsideSearchByPartHash && !isInsideDropdownMenuSearchIcon) {
        this.searchByPartHashInput = 'none';
        this.searchValueByPartHash = "";

      }
      if (!isInsideSearchByPartName && !isInsideSearchByPartHash && !isInsideDropdownMenuSearchIcon) {
        this.searchBarEvent.emit(false);
      }

      if (isInsideSearchByPartName || isInsideSearchByPartHash) {
        const handlebuttonPress = document.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
            this.searchByPartNameInput = 'none';
            this.searchByPartHashInput = 'none';
            this.searchBarEvent.emit(false);
            this.searchValueByPartName = "";
            this.searchValueByPartHash = "";
          }
        })
      }
    })
  }

  usernameObservable() {
    this.usernameSub = this.comm.usernameObservable.subscribe(
      {
        next: (value: boolean) => {
          if (value == true) {
            this.username = localStorage.getItem('Username');
          }
          else if (value == false) {
            this.username = null;
          }
        },
        error: (error: Error) => {
          //later log it into a file
          console.error('Custom Error: Unexpected Error while fetching username', error);
        },
      }
    )
  }

  onMouseHover(): void {
    this.dropdownSearchMenu = 'block';
  }

  onMouseLeave(): void {
    this.dropdownSearchMenu = 'none';
  }

  onClickSearchByPartName(): void {
    this.searchResultArrayByPartName = []
    this.dropdownSearchMenu = 'none'
    this.searchByPartHashInput = 'none'
    this.searchBarEvent.emit(true);
    this.searchByPartNameInput = 'block';
  }

  onClickSearchByPartHash(): void {
    this.searchResultArrayByPartHash = []
    this.dropdownSearchMenu = 'none'
    this.searchByPartNameInput = 'none'
    this.searchBarEvent.emit(true);
    this.searchByPartHashInput = 'block';
  }

  getLoggedInUserName(): string {
    this.username = localStorage.getItem('Username');
    return this.username;
  }

  getOrderItems(): void {
    const previousItemCount = this.cartItems;
    this.cartItems = +localStorage.getItem('ItemCount') || 0;

    // Check if an item was recently added by looking for the 'ItemRecentlyAdded' flag
    if (localStorage.getItem('ItemRecentlyAdded') && this.cartItems > previousItemCount) {
      this.showMessage = true;
      setTimeout(() => this.showMessage = false, 3000);
      localStorage.removeItem('ItemRecentlyAdded');
    }

    // Update subscription to check for item count changes
    this.comm.itemCount.subscribe(() => {
      const newItemCount = +localStorage.getItem('ItemCount') || 0;
      if (localStorage.getItem('ItemRecentlyAdded') && newItemCount > this.cartItems) {
        this.showMessage = true;
        setTimeout(() => this.showMessage = false, 3000);
        localStorage.removeItem('ItemRecentlyAdded');
      }
      this.cartItems = newItemCount;
    });
  }


  searchChangeByPartName(){
    this.searchSubject.pipe(
      debounceTime(300), // Adjust the debounce time as needed
      switchMap(value => this.productService.productByPartNameSearchResults(value))
    )
    .subscribe(
      {
        next:(resultArray: ProductVariationSearchInterface[]) =>
        {
          this.searchResultArrayByPartName = [];
          if (resultArray?.length > 0) {
            this.searchResultArrayByPartName = resultArray;
          }
        },
        error:(error: Error) =>
        {
          // Later log it into a file
          // console.log('Custom Error: Unexpected Error while fetching data from products SearchByPartName', error);
        }
      }
    );
  }
  onInputvalueChangeByPartName(): void {
      this.searchSubject.next(this.searchValueByPartName);
  }

  searchChangeByPartHash(){
    // Handle search for part hash
    this.partHashSubject.pipe(
      debounceTime(300), // Adjust the debounce time as needed
      switchMap(value => this.productService.productByPartHashSearchResults(value))
    )
    .subscribe(
      {
        next:(resultArray: ProductVariationSearchInterface[]) =>
        {
          this.searchResultArrayByPartHash = [];
          if (resultArray?.length > 0) {
            this.searchResultArrayByPartHash = resultArray;
          }
        },
        error:(error: Error) =>
        {
          // console.log('Custom Error: Unexpected Error while fetching data from product-variations searchByPartHash', error);
        }
      }
    );

  }

  onInputvalueChangeByPartHash(): void {
    this.partHashSubject.next(this.searchValueByPartHash);
  }

  onClickSearchByPartNameResult(productKey: number,productName: string): void{
    const name: string = this.commonService.setUrlNameField(productName);
    localStorage.removeItem('selectedproductvariationkey');
    this.product_variation_key = null;
    if(this.isProductPage && this.product_key != productKey){
      localStorage.setItem('selectedproductkey',String(productKey));
      this.product_key = productKey;
      this.comm.searchByPartNameProductKeyChange.next(null);
    }
    else if(this.isProductPage && this.product_key == productKey){
    }
    else if(!this.isProductPage && this.product_key != productKey){
      localStorage.setItem('selectedproductkey',String(productKey));
      this.product_key = productKey;
    }
    this.router.navigate(['/'+name,'p'+productKey]);
    this.comm.serchByPartHashKeyChange.next(null);
  }

  onClickSearchByPartHashResult(productKey: number,productVariationKey: number,productName: string,sku: string) : void{
    const name = this.commonService.setUrlNameField(productName);
    const skuName = this.commonService.setUrlNameField(sku)
    if (sku.includes(' ')) {
      localStorage.setItem('replaceDashSku', String(true));
    }
    if(!this.isProductPage) {
      localStorage.setItem('selectedproductkey',String(productKey));
      if(this.product_key != productKey) {
        this.product_key = productKey;
        localStorage.setItem('selectedproductvariationkey',String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.router.navigate(['/'+name,skuName,'p'+productKey])
      }
      else if(this.product_key == productKey && this.product_variation_key != productVariationKey){
        localStorage.setItem('selectedproductvariationkey',String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.router.navigate(['/'+name,skuName,'p'+productKey])
      }
      else if(this.product_key == productKey && this.product_variation_key == productVariationKey){
        this.router.navigate(['/'+name,skuName,'p'+productKey])
      }
    }
    else if (this.isProductPage){
      if(this.product_key != productKey){
        localStorage.setItem('selectedproductkey',String(productKey));
        this.product_key = productKey;
        localStorage.setItem('selectedproductvariationkey', String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.comm.searchByPartNameProductKeyChange.next(null);
      }
      else if(this.product_key == productKey && this.product_variation_key != productVariationKey){
        localStorage.setItem('selectedproductvariationkey',String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.comm.serchByPartHashKeyChange.next(null);
      }
      else if(this.product_key == productKey && this.product_variation_key == productVariationKey){
        return;
      }
    }
  }
  ngOnDestroy(): void {
    if (this.usernameSub) {
        this.usernameSub.unsubscribe();
    }
    if (this.corporateUserSub) {
        this.corporateUserSub.unsubscribe();
    }
    if (this.dropdownDataSub) {
        this.dropdownDataSub.unsubscribe();
    }
}

  goToLogin() {
    const currentUrl = this.router.url;
    localStorage.setItem('redirectAfterLogin', currentUrl);
    this.router.navigate(['/customer-login']);
  }

  openVechicleSearchPopup(): void {
    this.dialog.closeAll();
    this.dialog.open(SearchByVehicleComponent, {
    });
  }

}
